<template>
  <Breadcrumb />
  <div
    class="accordion mb-3 mt-2"
    :id="String.format('Accordion_{0}', gridSettings.action)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('Accordion_{0}_head', gridSettings.action)"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="
            String.format('#Accordion_{0}_tab1', gridSettings.action)
          "
          aria-expanded="true"
          :aria-controls="
            String.format('Accordion_{0}_tab1', gridSettings.action)
          "
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "Users.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('Accordion_{0}_tab1', gridSettings.action)"
        class="accordion-collapse collapse show"
        :aria-labelledby="
          String.format('Accordion_{0}_head', gridSettings.action)
        "
        :data-bs-parent="String.format('Accordion_{0}', gridSettings.action)"
      >
        <div class="accordion-body">
          <div class="row align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "Users.UserGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                :resetSelect="selectsReset"
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                requestUrl="/Lcdp-SummaryOrganizationalUnitItems?name=UserGroup&filterType=2"
                @onChange="onChangeUserGroup"
                :isParameters="true"
                :isGatewayRequest="true"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "Users.PermissionGroups",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                :resetSelect="selectsReset"
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                requestUrl="/Lcdp-SummaryOrganizationalUnitItems?name=PermissionGroup&filterType=3"
                @onChange="onChangePermissionGroup"
                :isParameters="true"
                :isGatewayRequest="true"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              {{
                $t(
                  "Users.ActivePassive",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-10">
              <FormSelect
                id="ActivePassive"
                type="SelectWithLocalData"
                :resetSelect="selectsReset"
                :selectedData="selectedActivePassive"
                :allowEmpty="false"
                :data="activePassiveData"
                @onChange="onChangeActivePassive"
              />
            </div>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button
              type="button"
              class="btn btn-success btn-history-filter-search me-2"
              @click="onSearch('btn-history-filter-search')"
            >
              <span>
                {{
                  $t(
                    "Users.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-history-filter-clear btn-danger"
              @click="onClear"
            >
              {{
                $t("Users.Clear", {}, { locale: this.$store.state.activeLang })
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t("Users.NewButton", {}, { locale: this.$store.state.activeLang })
      }}</router-link
    >
  </div>
  <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        class="nav-link active"
        id="pills-users-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-users"
        type="button"
        role="tab"
        aria-selected="true"
        @click="userTabClick('view')"
      >
        {{ $t("Users.Title", {}, { locale: this.$store.state.activeLang }) }}
      </button>
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-deleted-users-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-deleted-users"
        type="button"
        role="tab"
        aria-selected="false"
        @click="userTabClick('deleted-users')"
      >
        {{
          $t("Users.DeletedUsers", {}, { locale: this.$store.state.activeLang })
        }}
      </button>
    </li>
  </ul>
  <div class="tab-content">
    <div class="tab-pane fade active show" id="pills-users" role="tabpanel">
      <Grid
        v-if="!showDeletedUsersGrid"
        :settings="gridSettings"
        :columns="gridColumns"
        @onUserPasswordChangeButtonClick="onUserPasswordChangeButtonClick"
        @onRequestFinally="onSearchEnd"
      />
    </div>
    <div class="tab-pane fade" id="pills-deleted-users" role="tabpanel">
      <Grid
        v-if="showDeletedUsersGrid"
        ref="deleteUsersGrid"
        :settings="deletedUsersGridSettings"
        :columns="deletedUsersGridColumns"
        @onUndoDeletedUserButtonClick="onUndoDeletedUserButtonClick"
      />
    </div>
  </div>
  <PasswordChangeModal :userData="userData" />
  <AreYouSureModal
    :modalType="areYouSureModalType"
    :warningMessage="areYouSureModalWarningMessage"
    :requestParameter="areYouSureModalRequestParameter"
    :isGatewayAxios="true"
  />
</template>
<script>
import $ from "jquery";
import PasswordChangeModal from "@/components/page-partials/user/PasswordChangeModal.vue";
import AreYouSureModal from "@/components/layout/AreYouSureModal.vue";
export default {
  name: "UsersList",
  components: { PasswordChangeModal, AreYouSureModal },
  data() {
    return {
      areYouSureModalType: "",
      areYouSureModalRequestParameter: "",
      areYouSureModalWarningMessage: "",
      showDeletedUsersGrid: false,
      selectsReset: false,
      activePassiveData: [
        {
          key: 1,
          value: this.$t(
            "BaseModelFields.Active",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BaseModelFields.Passive",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedActivePassive: [],
      gridSettings: {
        action: "UsersList",
        requestUrl: String.format("/Lcdp-ListUsers"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "userId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],

        serialNoLink: `#/User/Edit/`,

        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-DeleteUser",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "passwordChange",
            cssClass: "btn-info",
            iconClass: "bi-key",
            routeButton: false,
            emitMethodName: "onUserPasswordChangeButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#userPasswordChangeModal",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.PasswordChangeModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "cache",
            cssClass: "btn-success",
            iconClass: "bi-database",
            routeButton: true,
            routeObj: {
              name: "UserCache",
              params: {
                userId: "",
              },
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Users.CacheTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "permissions",
            cssClass: "btn-primary",
            iconClass: "bi-lock",
            routeButton: true,
            routeObj: {
              name: "UserPermission",
              params: {
                userId: "",
              },
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Users.PermissionSettings",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "UserEdit",
              params: {
                userId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "Users.FirstName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "firstName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.LastName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lastName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "email",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.PermissionGroups",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "permissionGroups",
          type: "object",
          visible: true,
          width: 250,
          template: "permissionGroupTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.UserGroups",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "userGroups",
          type: "object",
          visible: true,
          width: 250,
          template: "userGroupTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.IsApiUser",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isApiUser",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
      deletedUsersGridSettings: {
        action: "DeletedUsersList",
        requestUrl: String.format("/Lcdp-ListDeletedUsers"),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "userId",
            value: "publicId",
            isRouteParameter: false,
          },
        ],
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "undo-delete",
            cssClass: "btn-secondary",
            iconClass: "bi bi-arrow-counterclockwise",
            routeButton: false,
            emitMethodName: "onUndoDeletedUserButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalAreYouSure",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Users.UndoDelete",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
        ],
      },
      deletedUsersGridColumns: [
        {
          text: this.$t(
            "Users.FirstName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "firstName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.LastName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lastName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "email",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      userData: {},
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
    };
  },
  methods: {
    userTabClick(type) {
      this.showDeletedUsersGrid = type === "deleted-users";
    },
    onUserPasswordChangeButtonClick(rowData) {
      this.userData = rowData;
    },
    onChangeUserGroup(selected) {
      this.userGroupPublicId = selected.key;
    },
    onChangePermissionGroup(selected) {
      this.permissionGroupPublicId = selected.key;
    },
    onChangeActivePassive(selected) {
      if (selected.key == 1) {
        this.isActive = "true";
      } else if (selected.key == 2) {
        this.isActive = "false";
      } else {
        this.isActive = "";
      }
    },
    onSearch(buttonName) {
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var searchUrl = "Lcdp-ListUsers",
        requestParameters = [];
      if (!String.isNullOrWhiteSpace(this.userGroupPublicId)) {
        requestParameters.push({
          key: "userGroup",
          value: this.userGroupPublicId,
        });
      }

      if (!String.isNullOrWhiteSpace(this.permissionGroupPublicId)) {
        requestParameters.push({
          key: "permissionGroup",
          value: this.permissionGroupPublicId,
        });
      }

      if (!String.isNullOrWhiteSpace(this.isActive)) {
        requestParameters.push({
          key: "isActive",
          value: this.isActive,
        });
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = String.createGetUrl(
        searchUrl,
        requestParameters
      );
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
    onUndoDeletedUserButtonClick(rowData, e) {
      this.areYouSureModalType = "UndoDeleteUser";
      this.areYouSureModalRequestParameter = rowData.publicId;
      this.areYouSureModalWarningMessage = this.$t(
        "Components.AreYouSureModal.UndoDeleteWarning",
        {},
        { locale: this.$store.state.activeLang }
      );
      // this.$prodGatewayAxios
      //   .post(String.format("/Lcdp-UndoDeleteUser?id={0}", rowData.publicId))
      //   .then((response) => {
      //     if (response.IsOk) {
      //       this.$refs.deleteUsersGrid.grid.refresh();
      //     }
      //   })
      //   .catch(function (error) {
      //     //alert(error);
      //   });
    },
    onClear() {
      this.selectsReset = !this.selectsReset;
    },
  },
  mounted() {},
};
</script>
